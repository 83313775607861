import React from "react"
import BodyLayout from "../components/bodyLayout"
import { Col, Row, Icon } from "antd"
import Img from "gatsby-image"
import SEO from '../components/SEO'

export default ({data}) => (
  <BodyLayout color='rgb(116, 210, 231)'>
  <SEO title={'Statiste - Site web'} description={"Conception de site web statiques"} />
  <div>
  <Row style={{paddingTop:30}}>
  <Col xs={{span:22,offset:1}} md={{span:20,offset:2}}>
    <Row type="flex" align="middle" left="space-between">
      <Col span={8}>
      <Img fluid={data.profile.childImageSharp.fluid} />
      </Col>
      <Col span={14} offset={1}>
      <h1 id="gir" style={{border: '3px dashed'}}>Des sites web statiques</h1>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour qui ?</h3>
      <p>
      <span id="sws">Tous ceux qui veulent être présents en ligne</span> sans avoir à dépenser des dizaines de milliers d'euros.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Comment ?</h3>
      <p>
      L'offre DQPM est basée sur une technologie testée et approuvée par <a href="https://www.gatsbyjs.org/showcase/?filters%5B0%5D=Featured">plusieurs grandes et petites entreprises: Gatsby.js</a> et
      hébergée gratuitement ce
      qui garantit que les <span id="sws">frais de maintenance sont réduits au maximum et plus précisément</span> aux seules modifications du design et le renouvellement du nom de domaine.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={24} offset={0} align="left">
      <h3>Pour quand ?</h3>
      <p>
      <span id="sws">En moyenne, 2 semaines</span> de travail sont nécessaires.
      </p>
      </Col>
    </Row>
    <Row type="flex" style={{marginTop:25}}>
      <Col span={23} offset={0} align="left">
      <h4>Le coût d'un site web dépendra essentiellement de deux choses:</h4>
      <p><Icon type="right"/>Le nombre de designs différents utilisés et non du nombre de pages que le site web contiendra.</p>
      <p>Par exemple, une page d'accueil, des articles, et une grille d'images comptera pour 3 designs différents.</p>
      <p><Icon type="right"/>De la complexité du design souhaité, plus le design est unique et plus le travail à effectuer est important.</p>
      </Col>
    </Row>
  </Col>
  </Row>
  </div>
  </BodyLayout>
)

export const query = graphql`
  {
    profile: file(relativePath: {eq:"img/landing_helicopter.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1000){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `
